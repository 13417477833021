import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
//import cn from "classnames";
import styles from "./Footer.module.sass";
//import Group from "./Group";
//import Image from "../Image";
//import Form from "../Form";
//import Theme from "../Theme";
import axios from 'axios';
//import Swal from 'sweetalert2';
//import Checkbox from './../Checkbox/index';
import { PopUpAlert } from "../../controller/utils";
//import Icon from "../Icon";
import { useHistory } from "react-router";
import SocialIcons from "../SocialIcons";

const menuItems = [
    {
        title: "",
        menu: [
            {
                title: "About Us"
                , url: "/About"
            }
            /*
            , {
                title: "FAQs"
                , url: "/faqs"
            }
            , {
                title: "Terms & Conditions"
                , url: "/terms-and-conditions"
            }
            , {
                title: "Privacy Policy"
                , url: "/privacy-policy"
            }
            , {
                title: "Shipping Policy"
                , url: "/shipping-policy"
            }
            , {
                title: "Returns Policy"
                , url: "/returns-policy"
            }
            */
            , {
                title: "Strategy"
                //, url: "/strategy"
                , externalLink: 'https://docsend.com/v/ksnss/business-plan-community'
            }
            , {
                title: "Portfolio"
                , url: "/search"
            }
            //, {
            //    title: "News"
            //    , url: "/news"
            //}
            , {
                title: "Contact"
                , url: "/contactus"
            }
            // {
            //   title: "Support",
            //   url: "",
            // },
        ]
    }
];

const legalLinks = [
    {
        title: "Terms & Conditions"
        , url: "/terms-and-conditions"
        , target: '_blank'
    }
    , {
        title: "Privacy Policy"
        , url: "/privacy-policy"
        , target: '_blank'
    }
];

const Footer = () => {

    //const history = useHistory();
    const [email, setEmail] = useState("");
    const [terms, setTerms] = useState(false);

    /*
    const handleSub = (e) => {
        e.preventDefault();
        if (email) {
            if (!terms) {
              PopUpAlert('', 'Please check the Privacy policy to subscribe', 'error')
              return
            }
        } else {
            PopUpAlert('Alert', 'Please enter your email', 'error')
            return
        }
        const url = process.env.REACT_APP_API_URL;
        axios.post(url + '/user/subscribe', { email }).then(res => {
            setEmail('')
            setTerms(false)
            PopUpAlert('Great', 'You are successfully registered for Email Newsletter', 'success')
        }).catch(err => { PopUpAlert('Alert..', err.response.data.message, 'error') })
    }
    */

    /*
    const homePage = (id) => {
        if (id === 1) window.open('https://www.facebook.com/', "_blank")
        if (id === 2) window.open('https://instagram.com/', "_blank")
        // if (id === 3) window.open('https://twitter.com/VarsityGems', "_blank")
    }
    */

    useEffect(() => {
        console.log('Footer: useEffect()');

        const script = document.createElement('script');
        //script.async = true
        script.src = 'https://agent.d-id.com/v1/index.js';
        script.type = 'module';
        script.setAttribute('data-name', 'did-agent');
        script.setAttribute('data-mode', 'fabio');
        //script.setAttribute('data-client-key', 'Z29vZ2xlLW9hdXRoMnwxMDIzMjQ5MTE5Mzg1NjU2NzU0NjI6Uk9fQnlzQW9kOE1ZSDZGaDZpTklJ');
        script.setAttribute('data-client-key', 'Z29vZ2xlLW9hdXRoMnwxMDIzMjQ5MTE5Mzg1NjU2NzU0NjI6Zk9rY1duaWhtUVJTQ1d1dGNvSGY2');
        script.setAttribute('data-agent-id', 'agt_bIwk5QSa');
        script.setAttribute('data-monitor', 'true');

        let footerElement = document.getElementById('realsplit-footer')
        if (footerElement) footerElement.appendChild(script);

        /*
        return () => { // Unmount
            let footerElement = document.getElementById('realsplit-footer')
            if (footerElement) footerElement.removeChild(script); // clean up the script when the component in unmounted
        }
        */

    }, [])

    return (
        <footer
            id = 'realsplit-footer'
            className = { styles.footer }
            >
            <div
                //className = {
                    //cn(
                    //    "container"
                    //    //, styles.container
                    //)
                    //    ""
                //}
                //className = 'max-width'
                //className = 'max-width'
                style = {{
                    padding: '0px 0px 0px 0px'
                    , maxWidth: '1500px'
                    , textAlign: 'center'
                    , margin: 'auto'
                }}
                >

                <div
                    //className = {styles.row}
                    className = 'container'
                    style = {{
                          display: 'flex'
                        , flexWrap: 'wrap'
                        //, justifyContent: 'space-between'
                        , justifyContent: 'space-around'
                        //, justifyContent: 'space-evenly'
                        //, width: '100%'
                        //, padding: '30px 0px'
                        //, border: 'red 2px solid'
                        , marginLeft: '30px'
                        , marginRight: '30px'

                    }}>

                    <div
                        className = 'footer-links'
                        //className = {styles.selectFooter}
                        style = {{
                              display: 'flex'
                            , flexWrap: 'wrap'
                            , gap: '24px'
                            , justifyContent: 'space-around'
                            , alignItems: 'center'
                            //, flexGrow: '1'
                            //, width: '100%'
                            //, padding: '30px 0px'
                            , paddingTop: '40px'
                            , paddingBottom: '20px'
                            , margin: 'auto'
                            , flex: 'auto'
                        }}
                        >
                        {
                            //externalLink
                            menuItems[0].menu.map(menuItem =>
                                {
                                    return (
                                        <div
                                            //key = { menuItem.url }
                                            key = { menuItem.title }
                                            className = { styles.title }
                                            style = {{
                                                flexGrow: '1'
                                            }}>
                                            {
                                                menuItem.externalLink
                                                ?
                                                    <a
                                                        href = { menuItem.externalLink }
                                                        target = '_blank'
                                                        style = {{
                                                              padding: '5px 0px'
                                                            , color: 'var(--color-gray-white, var(--White, #FFF))'
                                                            , fontFamily: 'Source Sans 3'
                                                            , fontSize: '22px'
                                                            , fontStyle: 'normal'
                                                            , fontWeight: '700'
                                                            , lineHeight: '150%'
                                                        }}
                                                        >{ menuItem.title }</a>
                                                :
                                                    <Link
                                                        className = { styles.logo }
                                                        to = { menuItem.url }
                                                        style = {{
                                                              padding: '5px 0px'
                                                            , color: 'var(--color-gray-white, var(--White, #FFF))'
                                                            , fontFamily: 'Source Sans 3'
                                                            , fontSize: '22px'
                                                            , fontStyle: 'normal'
                                                            , fontWeight: '700'
                                                            , lineHeight: '150%'
                                                        }}>
                                                        { menuItem.title }
                                                    </Link>
                                            }
                                        </div>
                                    )
                                }
                            )
                        }

                        {/*
                        <div className = { styles.media }>
                            <button style = {{ marginLeft: 20, backgroundColor: "transparent" }}>
                                <img src="/images/icons/instagram-icon.png" width="22" height="22" style={{ marginRight: 12 }} />
                            </button>
                            <button style = {{ backgroundColor: "transparent" }}>
                                <img src="/images/icons/facebook-icon.png" width="22" height="22" style={{ marginRight: 12 }} />
                            </button>
                        </div>
                        */}

                    </div>

                    <div
                        className="social-icons"
                        style = {{
                              verticalAlign: 'middle'
                            , marginTop: 'auto'
                            , marginBottom: 'auto'
                            , padding: '40px 0px 20px 0px'
                            , textAlign: 'center'
                            , minWidth: '200px' // temporary until we have more social links to add here
                            //, border: 'blue 2px solid'
                        }}
                        >
                        <SocialIcons key="twitter" type="twitter" testColor = 'white' height="28px" width="28px" />
                        {/*
                        <SocialIcons type="facebook" testColor = 'white' height="32px" width="32px" />
                        <SocialIcons type="instagram" testColor = 'white' height="32px" width="32px" />
                        <SocialIcons type="linkedin" testColor = 'white' height="32px" width="32px" />
                        */}
                    </div>


                </div>
            </div>

            <div>
                <div
                    //className="container"
                    style = {{
                         display: 'flex'
                        , justifyContent: 'center'
                        , alignItems: 'center'
                        , paddingTop: '20px'
                        , paddingBottom: '20px'
                    }}
                    >
                    {
                        legalLinks.map(legalLink =>
                            {
                                return (
                                    <div
                                        key = { legalLink.url }
                                        className = { styles.title }
                                        style = {{
                                        //    flexGrow: '1'
                                            padding: '0px 17px'
                                        }}
                                        >
                                        <Link
                                            className = { styles.logo }
                                            to = { legalLink.url }
                                            target = { legalLink.target ? legalLink.target : '' }
                                            style = {{
                                                  padding: '5px 0px'
                                                , color: 'var(--color-gray-white, var(--White, #FFF))'
                                                , fontFamily: 'Source Sans 3'
                                                , fontSize: '18px'
                                                , fontStyle: 'normal'
                                                , fontWeight: '700'
                                                , lineHeight: '150%'
                                                , characterSpacing: '-1px'
                                            }}
                                            >
                                            { legalLink.title }
                                        </Link>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>

            {/*
            <div className={cn("container", styles.foot)}>
            */}
            <div>
                <div
                    //className="container"
                    style = {{
                        //paddingBottom: '50px'
                        padding: '20px 20px 50px 20px'
                    }}
                    >
                    <div className= { styles.copyright }>
                        {/*
                        Copyright &copy; RealSplit&trade;, 2023
                        */}
                        Copyright &copy; RealSplit, 2023
                    </div>
                </div>
            </div>
            
        </footer>
    );
};

export default Footer;
